function Navbar({params}) {  
    return (
        <div className='NavBar'>
            <div className='NavBarElement'>
                <a>Tools</a>
            </div>
            <div className='NavBarElement'>
                <a>Blog</a>
            </div>
            <div className='NavBarElement'>
                <a>Case Studies</a>
            </div>
            <div className='NavBarElement'>
                <a>Contact</a>
            </div>            
        </div>      
    );
}
export default Navbar;

function hold(){
    <a className="text"  target="_blank">
                <div className="outerTile">
                    <div className="tile">
                        <div className="left">
                            <img className="logo" ></img>
                            <a>aaaaaa</a>
                        </div>
                        <div className="right">
                            <a className="text" >bbbb</a><br/>
                        </div>      
                    </div>
                </div>
            </a>
}